import { template as template_cc9ab9ecbefa4575ba12341c323008e6 } from "@ember/template-compiler";
const FKControlMenuContainer = template_cc9ab9ecbefa4575ba12341c323008e6(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
